import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery, useQueryClient } from "react-query";

import Styles from "./Season.module.scss";
import Layout from "../../Components/Layout/Layout";
import GlobalProgress from "../../Components/GlobalProgress/GlobalProgress";
import WorkoutHighTouchRight from "../../Assets/WorkoutHighTouchRight.svg";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";

import ROOT_URL from "../../Config/rootUrl";

const Season = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();

const { data, isLoading, isError } = useQuery(["getSeasonProgress"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/season/progress`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.status === 404) {
        return { message: "No ongoing season found." };
    }

    const responseJson = await response.json();
    if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
    }
    return responseJson;
});

  const globalProgress = useQuery(["getGlobalProgress"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/global-progress/read/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        progress: "global-progress",
      }),
    });

    const responseJson = await response.json();
    if (responseJson?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    if (data && !data.message) {
      const currentDate = new Date();
      const ongoingSegment = data.seasonSegments.find((segment) => {
        const startDate = new Date(segment.startDate);
        const endDate = new Date(segment.endDate);
        return currentDate >= startDate && currentDate <= endDate;
      });

      if (ongoingSegment) {
        setTimeLeft(calculateTimeLeft(ongoingSegment.endDate));
        const timer = setInterval(() => {
          const newTimeLeft = calculateTimeLeft(ongoingSegment.endDate);
          setTimeLeft(newTimeLeft);

          if (Object.keys(newTimeLeft).length === 0) {
            clearInterval(timer);
            queryClient.invalidateQueries(["getSeasonProgress"]);
          }
        }, 1000);

        return () => clearInterval(timer);
      }
    }
  }, [data, queryClient]);

  function calculateTimeLeft(endDate) {
    if (!endDate) return {};

    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <div>Error loading data. Please try again.</div>;
  if (data?.message === "No ongoing season found.") {
    return (
      <Layout>
        <div className={Styles.pageHeader}>
          {userDetails?.user?.role && userDetails.user.role === "coach" ? (
            <div className={Styles.userRole}>
              <object
                className={Styles.svgAnimation}
                type="image/svg+xml"
                data={WorkoutHighTouchRight}
                aria-label="animation"
              />
              <p>[ExerCube] Coach</p>
            </div>
          ) : null}

          <h1>{data?.username || userDetails?.user?.username}</h1>

          {!!globalProgress?.data && !globalProgress?.data?.error && (
            <GlobalProgress data={globalProgress.data} />
          )}
        </div>
        <div className={Styles.noSeason}>No ongoing season found.</div>
      </Layout>
    );
  }

  const { name, description, flavourImage, seasonSegments } = data;
//   const { name, description, flavourImage, achievementImage, seasonPrice, seasonSegments } = data;
//   const bannerImage = seasonPrice?.bannerImage;

  // Find the ongoing segment based on the current date
  const currentDate = new Date();
  const ongoingSegment = seasonSegments.find(segment => {
    const startDate = new Date(segment.startDate);
    const endDate = new Date(segment.endDate);
    return currentDate >= startDate && currentDate <= endDate;
  });

//   console.log("ongoingSegment", ongoingSegment);

  return (
    <Layout>
      <div className={Styles.pageHeader}>
        {userDetails?.user?.role && userDetails.user.role === "coach" ? (
          <div className={Styles.userRole}>
            <object
              className={Styles.svgAnimation}
              type="image/svg+xml"
              data={WorkoutHighTouchRight}
              aria-label="animation"
            />
            <p>[ExerCube] Coach</p>
          </div>
        ) : null}

        <h1>{data?.username || userDetails?.user?.username}</h1>

        {!!globalProgress?.data && !globalProgress?.data?.error && (
          <GlobalProgress data={globalProgress.data} />
        )}
      </div>

      <div className={Styles.seasonContainer}>
        {flavourImage && <img src={`${ROOT_URL}${flavourImage}`} alt="" />}

        <div className={Styles.seasonOverlay}>
          {description && (
            <h5>
              <span className={Styles.teal}>{description}</span>
            </h5>
          )}
          {name && (
            <h2>
              <span className={Styles.purple}>{name}</span>
            </h2>
          )}

          {ongoingSegment?.seasonChallenges?.length > 0 && (
            <>
              <div className={Styles.challengeContainer}>
                {/* <h3>Challenges</h3> */}
                {ongoingSegment?.seasonChallenges?.map((challenge) => (
                  <div key={challenge.id} className={Styles.challenge}>
                    <div>
                      <h4>{challenge.challengeType}</h4>
                      <p>{challenge.challengeConfig.name}</p>
                    </div>
                    {challenge.seasonChallengeProgresses.some(
                      (progress) => progress.completedAt
                    ) ? (
                      <div className={Styles.challengeCompleted}><p><span className={Styles.emoji}>&#10004;</span><span className={Styles.claimed}>Claimed</span></p></div>
                    ) : (
                      <div className={Styles.challengeOpen}><p><span className={Styles.emoji}>&#128274;</span><span className={Styles.expAmount}>+400</span><span className={Styles.expName}>EXP</span></p></div>
                    )}
                  </div>
                ))}
              </div>
              {Object.keys(timeLeft).length > 0 && (
                <div className={Styles.timer}>
                  <span className={Styles.timerText}>Time remaining: </span>{timeLeft.days}d {timeLeft.hours}h{" "}
                  {timeLeft.minutes}m {timeLeft.seconds}s
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* <pre style={{ fontSize: 7 }}>{JSON.stringify(data, null, 4)}</pre> */}
    </Layout>
  );
};

export default Season;