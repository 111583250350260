import UserHub from "../Pages/UserHub/UserHub";
import Workouts from "../Pages/Workouts/Workouts";
import Leaderboards from "../Pages/Leaderboards/Leaderboards";
import WorkoutDetail from "../Pages/WorkoutDetail/WorkoutDetail";
import Login from "../Pages/Login/Login";
import Register from "../Pages/Register/Register";
import EULA from "../Pages/EULA/EULA";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import VerifyEmail from "../Pages/VerifyEmail/VerifyEmail";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ChangePassword from "../Pages/ChangePassword/ChangePassword";
import TermsConditions from "../Pages/Terms-Conditions/Terms-Conditions";
import UploadAvatar from "../Pages/UploadAvatar/UploadAvatar";
import HealthData from "../Pages/HealthData/HealthData";
import Challenges from "../Pages/Challenges/Challenges";
import ChallengesPastWorkouts from "../Pages/ChallengesPastWorkouts/ChallengesPastWorkouts";
import ChallengesChooseOpponents from "../Pages/ChallengesChooseOpponents/ChallengesChooseOpponents";
import ChallengesOngoing from "../Pages/ChallengesOngoing/ChallengesOngoing";
import ChallengesPast from "../Pages/ChallengesPast/ChallengesPast";
import ChallengeDetail from "../Pages/ChallengeDetail/ChallengeDetail";
import Progress from "../Pages/Progress/Progress";
import CRUDUsers from "../Pages/CRUDUsers/CRUDUsers";
import CRUDUsersCreate from "../Pages/CRUDUsersCreate/CRUDUsersCreate";
import CRUDUsersUpdate from "../Pages/CRUDUsersUpdate/CRUDUsersUpdate";
import CRUDUsersWorkouts from "../Pages/CRUDUsersWorkouts/CRUDUsersWorkouts";
import CRUDUsersLicensekeys from "../Pages/CRUDUsersLicensekeys/CRUDUsersLicensekeys";
import Settings from "../Pages/Settings/Settings";
import QrScanner from "../Pages/QrScanner/QrScanner";
import GenerateLicenseKeys from "../Pages/GenerateLicenseKeys/GenerateLicenseKeys";
import ActivateLicenseKey from "../Pages/ActivateLicenseKey/ActivateLicenseKey";
import LeaderboardAttackOnTheCore from "../Pages/LeaderboardAttackOnTheCore/LeaderboardAttackOnTheCore";
import Quiz from "../Pages/Quiz/Quiz";
import Quizzes from "../Pages/Quizzes/Quizzes";
import QuizDetail from "../Pages/QuizDetail/QuizDetail";
import Ausbildung from "../Pages/Ausbildung/Ausbildung";
import SpeedCage from "../Pages/SpeedCage/SpeedCage";
import Season from "../Pages/Season/Season";

const routes = [
  {
    path: "/",
    component: UserHub,
    isPrivate: true,
  },
  {
    path: "/workouts",
    component: Workouts,
    isPrivate: true,
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false,
  },
  {
    path: "/register",
    component: Register,
    isPrivate: false,
  },
  {
    path: "/upload-avatar",
    component: UploadAvatar,
    isPrivate: true,
  },
  {
    path: "/health-data",
    component: HealthData,
    isPrivate: true,
  },
  {
    path: "/terms-conditions",
    component: TermsConditions,
    isPrivate: false,
  },
  {
    path: "/eula",
    component: EULA,
    isPrivate: false,
  },
  {
    path: "/verify_email",
    component: VerifyEmail,
    isPrivate: false,
  },
  {
    path: "/forgot_password",
    component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: "/change_password",
    component: ChangePassword,
    isPrivate: false,
  },
  {
    path: "/leaderboards",
    component: Leaderboards,
    isPrivate: true,
  },
  {
    path: "/workout/:id",
    component: WorkoutDetail,
    isPrivate: true,
  },
  {
    path: "/challenges",
    component: Challenges,
    isPrivate: true,
  },
  {
    path: "/challenges-ongoing",
    component: ChallengesOngoing,
    isPrivate: true,
  },
  {
    path: "/challenges-past",
    component: ChallengesPast,
    isPrivate: true,
  },
  {
    path: "/challenges-past-workouts",
    component: ChallengesPastWorkouts,
    isPrivate: true,
  },
  {
    path: "/challenges-choose-opponents",
    component: ChallengesChooseOpponents,
    isPrivate: true,
  },
  {
    path: "/challenge/:id",
    component: ChallengeDetail,
    isPrivate: true,
  },
  {
    path: "/progress",
    component: Progress,
    isPrivate: true,
  },
  {
    path: "/crud/users",
    component: CRUDUsers,
    isPrivate: true,
  },
  {
    path: "/crud/users/create",
    component: CRUDUsersCreate,
    isPrivate: true,
  },
  {
    path: "/crud/users/:id",
    component: CRUDUsersUpdate,
    isPrivate: true,
  },
  {
    path: "/crud/users/:id/workouts",
    component: CRUDUsersWorkouts,
    isPrivate: true,
  },
  {
    path: "/crud/users/:id/licensekeys",
    component: CRUDUsersLicensekeys,
    isPrivate: true,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: true,
  },
  {
    path: "/qrscanner",
    component: QrScanner,
    isPrivate: true,
  },
  {
    path: "/generate-licensekeys",
    component: GenerateLicenseKeys,
    isPrivate: true,
  },
  {
    path: "/activate-licensekey",
    component: ActivateLicenseKey,
    isPrivate: true,
  },
  {
    path: "/leaderboard-attack-on-the-core",
    component: LeaderboardAttackOnTheCore,
    isPrivate: true,
  },
  {
    path: "/quiz",
    component: Quiz,
    isPrivate: true,
  },
  {
    path: "/quizzes",
    component: Quizzes,
    isPrivate: true,
  },
  {
    path: "/quiz/:quizId",
    component: QuizDetail,
    isPrivate: true,
  },
  {
    path: "/ausbildung",
    component: Ausbildung,
    isPrivate: true,
  },
  {
    path: "/speedcage",
    component: SpeedCage,
    isPrivate: true,
  },
  {
    path: "/season",
    component: Season,
    isPrivate: true,
  },
  {
    path: "/*",
    component: PageNotFound,
    isPrivate: true,
  },
];

export default routes;
